<script setup>
import {
  OmnichannelChatSDK,
  isCustomerMessage,
} from '@microsoft/omnichannel-chat-sdk';
import { onMounted, onUnmounted, ref, toRaw } from 'vue';
import {
  ChatBubbleOvalLeftIcon,
  Bars3BottomRightIcon,
  XMarkIcon,
  MinusIcon,
} from '@heroicons/vue/24/solid';
import interact from 'interactjs';

import LoadingThingy from './components/LoadingThingy.vue';
import InternalSurveyForm from './components/InternalSurveyForm.vue';
import ChatFooter from './components/ChatFooter.vue';
import ChatEnd from './components/ChatEnd.vue';
import { departmentMapping, buildOOHMessage, loadChannelDetails, addAgentAvailabilityToChannel, extractDataFromIDC } from './rules';
import { MESSAGES, UNAVAILABLE_REASON } from './constants';
import messsageSound from './assets/message-received.mp3';

defineProps({
  isInternal: {
    type: Boolean,
    default: false
  }
})
const omnichannelConfig = {
  orgUrl: import.meta.env.VITE_ORG_URL,
  orgId: import.meta.env.VITE_ORG_ID,
  widgetId: import.meta.env.VITE_APP_ID,
};

const chatSDKConfig = {
  telemetry: {
    disable: true, // Disable telemetry
  },
};

const messageNotification = new Audio(messsageSound);
const audioPlayEnabled = ref(false);
const userMuted = ref(false);
const isLoading = ref(true);
const closedExtra = ref(false);
const showChatWidget = ref(false);
const preChatQuestions = ref([]);
const waiting_to_start = ref(null);
const initializeError = ref(null);
const preChatSurveyResponses = ref({
});
const chatMessages = ref([]);
const isTyping = ref(false);
const messageToSend = ref('');
const sendingMessage = ref(false);
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const emailTranscript = ref('');
const confirmCloseChat = ref(false);
const confirmDecisionState = ref(false);
const showPostChatSurvey = ref(false);
const showQualtricsSurvey = ref(false);
const transcriptSentFailed = ref('');
const transcriptSending = ref(false);
const transcriptSent = ref(false);
const currentChannel = ref("");
const chatId = ref(null);
const labelToIdMap = ref({});
const customerName = ref(null);
const agentName = ref(null);
const surveyUrl = ref(null);
const windowWidth = ref(window.innerWidth);
const windowHeight = ref(window.innerHeight);
const offset = ref(0);
const url = import.meta.env.VITE_AGENT_AND_OOH_INFO_URL
const utc_now = ref("");
const sendMessageError = ref('');
const activatorPosition = ref(null);
const containerPosition = ref(null);
const storedPreChatResponse = ref({});

// const videoCallingSDK = ref(null);
// const chatToken = ref(null);
onMounted(async () => {
  console.log('called onMounted');
  isLoading.value = false;
  const channelFromSession = JSON.parse(sessionStorage.getItem('MS365CurrentChannel'));
  if (channelFromSession) {
    await initializeChat(channelFromSession?.id || null, Boolean(channelFromSession?.id));
  }

  if (window.innerWidth >= 500) {
    activatorPosition.value = { left: (windowWidth.value - 144) + "px", top: (windowHeight.value - 144) + "px" };
    containerPosition.value = { left: (windowWidth.value - 496) + "px", top: (windowHeight.value - 600) + "px" };
  } else {
    // activatorPosition.value = { left: (windowWidth.value - 100) + "px", top: (windowHeight.value - 80) + "px" };
    activatorPosition.value = { bottom: "0px", right: "0px" };
    containerPosition.value = { top: "45px", left: "0px" };
  }
});

let chatSDK = null

const closeChatWithConfirmation = async (endIfNotStarted) => {
  try {
    if (chatSDK && chatSDK?.chatToken?.chatId) {
      if (confirm(MESSAGES.END_CHAT_CONFIRMATION) === true) {
        await chatSDK.endChat();
        cleanUp();
        clearSurvey();
      } else {
        throw new Error('Chat already in progress');
      }
    } else if (endIfNotStarted && !chatSDK?.chatToken?.chatId) {
      cleanUp();
      showChatWidget.value = false;
    }
  }
  catch (e) {
    isLoading.value = false;
    if (endIfNotStarted && !chatSDK?.chatToken?.chatId) {
      cleanUp();
      showChatWidget.value = false;
    }
    return;
  }
};


const initializeChat = async (widgetId, hasExistingSession = false) => {
  isLoading.value = true;
  if (!widgetId) {
    console.log('Widget Id is not provided');
    initializeError.value = MESSAGES.ERROR_INITIATE_CHAT
    isLoading.value = false;
    return
  }
  await closeChatWithConfirmation();
  if (!hasExistingSession) {
    cleanUp()
  }
  const _url = url + '?' + new URLSearchParams({
    "app_id": widgetId,
  });
  const channelDetails = await fetch(_url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
  let { data, utcNow } = channelDetails;
  data = {
    "name": data.channelName,
    "id": data.channelAppId,
    ...data
  }
  currentChannel.value = data;
  // Check for OOH First before agent availability
  const channelWithAvailablility = addAgentAvailabilityToChannel(data, utcNow, 0);
  if (channelWithAvailablility.isUnAvailable && channelWithAvailablility.reason === UNAVAILABLE_REASON.OOH) {
    console.log('Chat Initiated in OOH');
    const oohMessage = buildOOHMessage(data);
    initializeError.value = `${MESSAGES.OOH_MESSAGE} ${oohMessage}`;
    isLoading.value = false;
    showChatWidget.value = true;
    return
  }
  // Agent Availability Check
  // if (channelWithAvailablility.isUnAvailable && channelWithAvailablility.reason === UNAVAILABLE_REASON.NO_AGENTS) {
  //   console.log(MESSAGES.AGENTS_NOT_AVAILABLE);
  //   initializeError.value = MESSAGES.AGENTS_NOT_AVAILABLE
  //   isLoading.value = false;
  //   showChatWidget.value = true;
  //   return
  // }
  initializeError.value = '';
  chatSDK = new OmnichannelChatSDK({
    ...omnichannelConfig,
    widgetId: widgetId
  }, chatSDKConfig);
  await chatSDK.initialize();
  preChatQuestions.value = await chatSDK.getPreChatSurvey();
  const checkPreviousSession = sessionStorage.getItem('MS365ChatSession');
  if (checkPreviousSession) {
    try {
      const optionalParams = {};
      optionalParams.liveChatContext = JSON.parse(checkPreviousSession);
      await chatSDK.startChat(optionalParams);
      chatMessages.value = await chatSDK.getMessages();
      await initializeChatEvents();
      showChatWidget.value = true;
      closedExtra.value = true;
      preChatSurveyResponses.value = JSON.parse(
        sessionStorage.getItem('MS365ChatPreChatSurvey')
      );
      setTimeout(() => {
        document
          .getElementById('chatInputBox')
          ?.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('chatInputBox')?.focus();
      }, 500);
    } catch (e) {
      clearSessionStore()
    }
  }
  isLoading.value = false;
  showChatWidget.value = true;
}

const clearSessionStore = () => {
  sessionStorage.removeItem('MS365ChatSession');
  sessionStorage.removeItem('MS365ChatPreChatSurvey');
  sessionStorage.removeItem('MS365CurrentChannel');
}

onUnmounted(() => {
  console.log('called onUnmounted');
  //clearSessionStore()
});

// Called when the chat widget is toggled eg: minimise of maximise
const toggleChat = async () => {
  initializeAudio();
  //console.log('Called Toggle Chat');
  if (confirmCloseChat.value) return;

  showChatWidget.value = !showChatWidget.value;
  if (showChatWidget.value) {
    isLoading.value = true;
    closedExtra.value = true;
    isLoading.value = false;
    if (chatMessages.value.length > 0) {
      setTimeout(() => {
        document
          .getElementById('chatInputBox')
          ?.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('chatInputBox')?.focus();
      }, 500);
    }
  }
};

const initializeChatEvents = async () => {
  await chatSDK.onNewMessage((message) => {
    if (audioPlayEnabled.value) {
      messageNotification.play();
    }
    if (
      chatMessages.value.length === 1 &&
      chatMessages.value[0].id === '00000000-0000-0000-0000-000000000000'
    ) {
      chatMessages.value = [];
    }
    if (waiting_to_start.value !== null) {
      clearTimeout(waiting_to_start.value);
      waiting_to_start.value = null;
    }
    showChatWidget.value = true;
    if (
      chatMessages.value.length === 1 &&
      message.content === chatMessages.value[0].content
    ) {
      return;
    }
    chatMessages.value = [message, ...chatMessages.value];
    setTimeout(() => {
      document
        .getElementById('chatInputBox')
        ?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('chatInputBox')?.focus();
    }, 500);
    if (!isCustomerMessage(message)) {
      agentName.value = message.sender.displayName;
      chatId.value = chatSDK?.chatToken?.chatId;
    }
  });
  await chatSDK.onTypingEvent(() => {
    isTyping.value = true;
    setTimeout(() => {
      isTyping.value = false;
    }, 1000);
  });
  await chatSDK.onAgentEndSession(() => {
    //alert("Agent ended the session. Please refresh the page to start a new session.")
    surveyUrl.value = buildSurveyUrl(preChatSurveyResponses, chatId, agentName);
    clearSessionStore()
    endChat(true);
  });
  // chatToken.value = await chatSDK.getChatToken();
};

const checkAndSyncMessages = async () => {
  chatMessages.value = await chatSDK.getMessages();
  if (chatMessages.value.length === 0) {
    chatMessages.value = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        content: 'Connecting...',
        sender: {
          displayName: '',
        },
        timestamp: new Date().toISOString(),
      },
    ];
    setTimeout(() => {
      document
        .getElementById('chatInputBox')
        ?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('chatInputBox')?.focus();
    }, 500);
    waiting_to_start.value = setTimeout(async () => {
      chatMessages.value = await chatSDK.getMessages();
      if (chatMessages.value.length === 0) {
        chatMessages.value = [
          {
            id: '00000000-0000-0000-0000-000000000000',
            content: MESSAGES.WAITING_FOR_AGENT,
            sender: {
              displayName: '',
            },
            timestamp: new Date().toISOString(),
          },
        ];
      }
    }, 5000);
  }
};

const chatAction = (e) => {
  e.stopPropagation();
  toggleChat();
}


// Start Chat on click of start button inside chat or on click of chat icon if in sales page
const startChat = async (preChatResponse, mapping) => {


  for (const key in preChatResponse) {
    let parsedKey = JSON.parse(key).QuestionText;
    let parsedValue = "";
    if (preChatResponse[key].includes("Id")) {
      parsedValue = JSON.parse(preChatResponse[key]).Value;
    } else {
      parsedValue = preChatResponse[key];
    }
    storedPreChatResponse.value[parsedKey] = parsedValue;
  }

  // Reset transcriptSent to false
  transcriptSent.value = false;
  labelToIdMap.value = mapping
  customerName.value = preChatResponse[mapping["Name"]] ?? "";
  preChatResponse = {
    Type: 'InputSubmit',
    ...preChatResponse,
  };
  try {
    initializeAudio(true);
    isLoading.value = true;
    const customChatContext = {
      Source: { value: window?.location?.href || 'Unknown', isDisplayable: true },
    };
    await chatSDK.startChat({
      preChatResponse: preChatResponse,
      customContext: customChatContext,
      sendDefaultInitContext: true,
    });
    const chatContext = await chatSDK.getCurrentLiveChatContext();
    sessionStorage.setItem('MS365ChatSession', JSON.stringify(chatContext));
    sessionStorage.setItem(
      'MS365ChatPreChatSurvey',
      JSON.stringify(preChatSurveyResponses.value)
    );
    sessionStorage.setItem('MS365CurrentChannel', JSON.stringify(currentChannel.value));
    await initializeChatEvents();
    sendMessage(JSON.stringify(storedPreChatResponse.value));
    await checkAndSyncMessages();
    isLoading.value = false;
  } catch (e) {
    // alert('Something went wrong. Please try again.');
    isLoading.value = false;
    if (e.message === 'ConversationInitializationFailure') {
      initializeError.value = MESSAGES.ERROR_INITIATE_CHAT;
    } else if (e.message === 'WidgetUseOutsideOperatingHour') {
      initializeError.value = MESSAGES.AGENTS_NOT_AVAILABLE;
    }
  }
};

const initializeAudio = (unmute = false) => {
  if (!audioPlayEnabled.value) {
    messageNotification.play().then(() => {
      messageNotification.pause();
      messageNotification.currentTime = 0;
      audioPlayEnabled.value = true;
    });
  }
  if (unmute) {
    userMuted.value = false;
  }
  if (!audioPlayEnabled.value) {
    userMuted.value = true;
  }
};

const sendMessage = async (preChatQuestionsData) => {
  sendingMessage.value = true;
  if (preChatQuestionsData) {
    await chatSDK.sendMessage({
      id: '00000000-0000-0000-0000-000000000000',
      content: preChatQuestionsData,
      sender: {
        displayName: 'Customer',
      },
      timestamp: new Date().toISOString(),
    })
    sendingMessage.value = false;
    return;
  }
  if (messageToSend.value.length === 0) {
    return;
  }
  initializeAudio(false);
  try {
    await chatSDK.sendMessage({
      content: messageToSend.value,
    });
    chatMessages.value = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        content: messageToSend.value,
        sender: {
          displayName: 'Customer',
        },
        timestamp: new Date().toISOString(),
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    messageToSend.value = '';
    setTimeout(() => {
      var scrollContainer = document.getElementById('scrollContainer');
      scrollContainer?.scrollTo(0, scrollContainer.scrollHeight)
      document.getElementById('chatInputBox')?.focus();
    }, 100);
    sendMessageError.value = '';
  } catch (e) {
    sendMessageError.value = MESSAGES.SEND_MESSAGE_FAILURE;
    console.log(e);
    sendingMessage.value = false;
  }
};

const customerTyping = async (event) => {
  if (event.key === 'Enter' && messageToSend.value.length > 0) {
    await sendMessage();
  }
};

// Called when close button is clicked when in form screen
const endChat = async (confirmed = false) => {
  //console.log('called endChatWindow', confirmed);
  const copyOfPreChatSurveyResponse = JSON.parse(JSON.stringify(preChatSurveyResponses.value));
  if (!confirmed) {
    surveyUrl.value = buildSurveyUrl(preChatSurveyResponses, chatId, agentName, labelToIdMap);
  }
  if (confirmed) {
    preChatSurveyResponses.value = {};
    clearSessionStore()
  }
  if (chatMessages.value.length === 0) {
    showChatWidget.value = false;
    return;
  }
  confirmCloseChat.value = true;
  confirmDecisionState.value = true;
  if (confirmed) {
    if (window.location.pathname.includes("/idc") || localStorage.getItem("Authentication")) {
      const data = extractDataFromIDC();
      const email = data.state.user.email;
      emailTranscript.value = email.toLowerCase();
    }
    showPostChatSurvey.value = true;
  }
};

const cancelEndChat = () => {
  //console.log('called cancelEndChat');
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
};

const cleanFieldsAndValidations = () => {
  //console.log('called cleanFieldsAndValidations');
  preChatSurveyResponses.value = {};
};


const cleanUp = () => {
  // console.log('called cleanUp');
  cleanFieldsAndValidations();
  clearSessionStore();
  showChatWidget.value = false;
  closedExtra.value = false;
  chatMessages.value = [];
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
  showPostChatSurvey.value = false;
  chatId.value = null;
  agentName.value = null;
  surveyUrl.value = null;
  labelToIdMap.value = {};
  customerName.value = null;
  currentChannel.value = {};
  clearSurvey();
};

const closeChatWindow = async (endChat = true) => {
  // console.log('called closeChatWindow'.endChat);
  cleanUp();
  // End Chat only when transcript is not sent, else end chat is called in requestEmailTranscript
  if (endChat && !transcriptSent.value) {
    transcriptSent.value = false;
    await chatSDK.endChat();
  }
};

const startNewChat = async (_endChat = true) => {
  // console.log('called startNewChat'. endChat);
  let _currentChannelId = currentChannel?.value?.id;
  cleanUp();
  // Call end chat only when transcript is not sent, else end chat is called in requestEmailTranscript
  if (!transcriptSent.value) {
    transcriptSent.value = false;
    await chatSDK.endChat();
  }
  await initializeChat(_currentChannelId);
};

const downloadFile = (fileMetadata) => {
  chatSDK
    .downloadFileAttachment(toRaw(fileMetadata))
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = fileMetadata.name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      // Remove link from body
      document.body.removeChild(link);
    })
    .catch((e) => {
      console.log(e);
      // alert('Unable to download attachment.');
    });
};

const processUpload = async (evt) => {
  const file = evt.target.files[0];
  try {
    sendingMessage.value = true;
    const resp = await chatSDK?.uploadFileAttachment(file);
    //console.log(resp);
    chatMessages.value = [
      {
        id: resp.id,
        ...resp,
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    evt.target.value = null;
    //console.log(chatMessages.value);
  } catch (e) {
    console.log(e);
    // alert('Unable to upload file.');
  }
};

const requestEmailTranscript = async () => {
  if (!emailPattern.test(emailTranscript.value)) {
    transcriptSentFailed.value = 'Please enter a valid email.';
    return;
  }
  try {
    transcriptSending.value = true;
    transcriptSentFailed.value = '';
    await chatSDK.emailLiveChatTranscript({
      emailAddress: emailTranscript.value,
      attachmentMessage: 'Your Chat Transcript',
    });
    transcriptSending.value = false;
    transcriptSent.value = true;
    await chatSDK.endChat();
  } catch (e) {
    transcriptSending.value = false;
    console.log(e);
    transcriptSentFailed.value = 'Unable to email transcript.';
  }
};

//Set this once chatsdk is initialized
window.initiateChat = async (secretKey, widgetId) => {
  //console.log(secretKey, widgetId)
  if (secretKey === 'CAB724D7E74F5') {
    await initializeChat(widgetId);

  }
};

window.destroyChat = async (secretKey) => {
  if (secretKey === 'CAB724D7E74F5') {
    if (chatSDK && chatSDK?.chatToken?.chatId) {
      await chatSDK.endChat();
    }
    cleanUp();
  }
};

const buildSurveyUrl = (preChatSurveyResponses, chatId, agentName, labelToIdMap) => {
  labelToIdMap = labelToIdMap.value || {};
  const channelId = preChatSurveyResponses.value.channel_id;
  const agentNameFormatted = agentName.value ? `${channelId} -${agentName.value} ` : "";
  const chatIdFinal = chatId.value ? chatId.value : "";
  const customerName = preChatSurveyResponses.value[labelToIdMap["Name"] || ""] || "";
  const customerEmail = preChatSurveyResponses.value[labelToIdMap["Email"] || ""] || "";
  const customerPhone = preChatSurveyResponses.value[labelToIdMap["Phone Number"] || ""] || "";
  const location = departmentMapping.find((dept) => dept.dept === channelId)?.deptId || channelId || "";
  return `https://ziplyfiber.sjc1.qualtrics.com/jfe/form/SV_eqXCFSpkf31O68d?location=${location}&TriggeredAt=${new Date().toISOString()}&CustomerName=${customerName}&CustomerEmail=${customerEmail}&CustomerPhone=${customerPhone}&agent_id=${agentNameFormatted}&ChatId=${chatIdFinal}`;

}

const clearSurvey = () => {
  var qualtricsSurvey = document.getElementById('qualtricsSurvey');
  if (qualtricsSurvey) {
    qualtricsSurvey.style.display = 'none';
    if (qualtricsSurvey.childNodes.length > 0) {
      qualtricsSurvey.removeChild(qualtricsSurvey.childNodes[0]);
    }
  }
  showQualtricsSurvey.value = false;
};

const adjustWidget = () => {
  if (window.innerWidth >= 500) {
    interact("#chatActivator").draggable({
      listeners: {
        move: dragMoveListener,
      },
    });
  }
  function dragMoveListener(event) {
    let target = event.target;
    // Get the position of chatActivator
    let x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    let y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // Constrain chatActivator position within the viewport
    x = Math.min(Math.max(x, 0), window.innerWidth - target.offsetWidth - 20);
    y = Math.min(Math.max(y, 0), window.innerHeight - target.offsetHeight - 20);

    // Calculate the position for chatContainer
    let chatContainerX, chatContainerY;

    // Determine the horizontal position of chatContainer based on chatActivator
    if (x > window.innerWidth / 2) {
      chatContainerX = x - 400; // Open chat container to the left of chatActivator
    } else {
      chatContainerX = x + target.offsetWidth + 20; // Open chat container to the right of chatActivator
    }

    // Determine the vertical position of chatContainer based on chatActivator
    if (y > window.innerHeight / 2) {
      chatContainerY = y - 544; // Adjust the height of chatContainer as needed
    } else {
      chatContainerY = y; // No adjustment needed
    }

    // Constrain chatContainer position within the viewport
    chatContainerX = Math.min(Math.max(chatContainerX, 0), window.innerWidth - 400); // Adjusted to our chatContainer width
    chatContainerY = Math.min(Math.max(chatContainerY, 0), window.innerHeight - 544); // Adjusted to our chatContainer height

    // Set the position of chatContainer
    const chatContainer = document.getElementById("chatContainers");
    chatContainer.style.position = "fixed";
    containerPosition.value = { left: chatContainerX + "px", top: chatContainerY + "px" };

    // Update the position attributes of chatActivator
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);

    // Translate the chatActivator
    activatorPosition.value = { left: x + "px", top: y + "px" };
  }
}
adjustWidget();

window.addEventListener("resize", () => {
  if (window.innerWidth >= 500) {
    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
    activatorPosition.value = { left: (windowWidth.value - 144) + "px", top: (windowHeight.value - 144) + "px" };
    containerPosition.value = { left: (windowWidth.value - 496) + "px", top: (windowHeight.value - 600) + "px" };
    adjustWidget();
  }
  else {
    interact("#chatActivator").unset();
    activatorPosition.value = { bottom: "0px", right: "0px" };
    containerPosition.value = { top: "45px", left: "0px" };
  }
});

</script>

<template>
  <div id="chatWrapper" class="chatWrapper" v-if="currentChannel?.id">
    <!-- Chat Icon Start-->
    <div id="chatActivator" :data-x="(windowWidth - 96) + 'px'" :data-y="(windowHeight - 96) + 'px'"
      class="!zf-z-[1501] zf-w-max zf-flex zf-gap-4 zf-justify-end zf-fixed" :style="activatorPosition">
      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark !zf-bg-ziply-blue md:zf-w-24 md:zf-h-24 md:zf-rounded-[4px]"
        v-if="!showChatWidget" :disaled="isLoading" @click="chatAction($event)">
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center" v-if="!isLoading">
          <div class="!zf-text-center">
            <ChatBubbleOvalLeftIcon class="zf-w-6 zf-h-6  md:zf-w-12 md:zf-h-12 !zf-fill-gray-100"
              v-if="!showChatWidget" />
            <p class=" zf-text-3.5 md:zf-text-xl md:zf-flex zf-hidden zf-m-0 zf-text-white">
              Chat
            </p>
          </div>
        </div>
      </div>
      <div
        class="!zf-flex zf-items-center zf-justify-center !zf-px-4 !zf-h-fit !zf-py-2 zf-relative md:zf-flex zf-w-max"
        v-if="!isLoading && !showChatWidget">
        <button class="!zf-fill-white !zf-bg-ziply-blue-dark zf-rounded-full zf-absolute -zf-left-2 -zf-top-2 !zf-p-1"
          @click="closeChatWithConfirmation(true)">
          <XMarkIcon class="!zf-w-3 !zf-h-3 zf-fill-white" />
        </button>
      </div>
      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark zf-circle-button md:zf-w-16 md:zf-h-16 !zf-bg-[#000050]"
        v-if="showChatWidget" :disaled="isLoading">
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center" v-if="!isLoading">
          <div class="!zf-text-center">
            <Bars3BottomRightIcon class="zf-w-6 zf-h-6  md:zf-w-12 md:zf-h-12 !zf-fill-gray-100"
              v-if="showChatWidget" />
          </div>
        </div>
      </div>
    </div>

    <!-- Chat Icon End -->
    <div id="chatContainers" class="zf-w-max !zf-z-[1500] zf-fixed" :style="containerPosition">
      <div
        class="zf-fixed zf-bg-white zf-rounded zf-shadow zf-overflow-hidden !zf-w-12/12 !zf-h-[544px] sm:zf-w-[400px] !sm:zf-h-[546px] !zf-border !zf-border-white"
        v-if="showChatWidget">
        <div
          class="!zf-h-[9%] !zf-bg-ziply-blue !zf-text-gray-100 !zf-flex !zf-justify-between !zf-w-full !zf-items-center !zf-px-4 !zf-rounded-t">
          <MinusIcon class="!zf-h-6 !zf-w-6 !mr-3 !zf-fill-gray-100 zf-cursor-pointer" v-if="!confirmDecisionState"
            @click="toggleChat()" />
          <p class="!zf-text-center !zf-w-full !zf-text-lg zf-text-white zf-m-0">
            {{ currentChannel?.name ? `Chat with ${currentChannel?.name} ` : "Chat" }}
          </p>
          <XMarkIcon id="endChatButton" class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 !zf-cursor-pointer zf-end-chat-button"
            v-if="!confirmCloseChat" @click="endChat()" />
          <XMarkIcon id="closeChatButton"
            class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 !zf-cursor-pointer zf-close-chat-button"
            v-if="showQualtricsSurvey || confirmCloseChat" v-show="showPostChatSurvey" @click="closeChatWindow(true)" />
        </div>
        <!-- Chat Body-->
        <div class="!zf-h-[91.2%] !zf-w-full !zf-text-[#3c3c3c] zf-overflow-hidden">
          <!-- Welcome message and form -->
          <div v-if="chatMessages.length === 0" class="!zf-p-6 !zf-h-full !zf-overflow-scroll zf-no-scrollbar">
            <p class="!mb-8 !zf-text-justify" v-if="!isLoading && !initializeError">
              To proceed with this chat, please complete the following and click <strong>Start Chat</strong>.
            </p>
            <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="initializeError">
              <span class="zf-whitespace-pre-wrap">
                {{ initializeError }}
              </span>
            </p>
            <p v-if="isLoading">Processing...</p>
            <InternalSurveyForm :start-chat="startChat" v-if="!initializeError"
              :pre-chat-survey-responses="preChatSurveyResponses" :pre-chat-questions="preChatQuestions"
              :is-loading="isLoading" :is-internal="isInternal" />
          </div>
          <template v-else>
            <div class="!zf-flex  zf-flex-col !zf-gap-2 !zf-h-full" v-if="!confirmCloseChat">
              <!-- Chat messages -->
              <div
                class="!zf-flex !zf-flex-col-reverse !zf-h-[90%] !zf-px-4 !zf-py-2 !zf-overflow-scroll zf-no-scrollbar">
                <div v-for="message in chatMessages" :key="message.id" class="!zf-flex !zf-flex-col !zf-gap-1 ">
                  <div class="!zf-flex !zf-flex-col !zf-items-start" v-if="!isCustomerMessage(message)">
                    <div class="!zf-text-ziply-blue !zf-flex !zf-justify-center !zf-items-center">
                      <p class="!zf-text-sm !zf-text-ziply-blue !zf-font-normal">
                        {{ agentName || message.sender.displayName }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-100 zf-rounded-lg !zf-px-4 !zf-py-2" v-if="message.content !== '' &&
    typeof message.content !== 'object'
    ">
                      <p class="!zf-text-gray-800 !zf-text-sm !zf-text-left">
                        {{ message.content }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
                      v-if="message.fileMetadata !== undefined">
                      <button class="!zf-text-blue-600 !zf-text-sm zf-underline"
                        @click="downloadFile(Object.freeze(message.fileMetadata))">
                        {{ message.fileMetadata.name }}
                      </button>
                    </div>
                    <div v-if="message.timestamp !== ''">
                      <p class="zf-italic zf-text-xs zf-text-gray-500">
                        {{ new Date(message.timestamp).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                  <div class="!zf-flex !zf-flex-col !zf-items-end" v-else>
                    <div class="!zf-text-slate-800 !zf-rounded-full !zf-flex !zf-justify-center !zf-items-center">
                      <p class="!zf-text-sm !zf-font-normal !zf-text-gray-600">
                        {{ customerName || preChatSurveyResponses.name }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-200 !zf-rounded-lg !zf-p-2"
                      v-if="message.content !== '' && message.content.includes('Agent name')">
                      <div class=" !zf-text-gray-800 !zf-text-sm !zf-text-right ">
                        <div v-for=" ( value, key ) in  JSON.parse(message.content) " :key="key">
                          <table class="zf-w-full zf-border zf-rounded-lg">
                            <tr class="zf-w-full zf-border zf-rounded-lg">
                              <th class="zf-w-2/4 zf-text-center zf-p-1">{{ key }}</th>
                              <td class="zf-w-2/4 zf-text-center zf-p-1">{{ value }}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div class="!zf-bg-gray-200 !zf-rounded-lg !zf-p-2" v-if="message.content !== '' &&
    typeof message.content !== 'object' && message.content.includes('Agent name') === false
    ">
                      <p class="!zf-text-gray-800 !zf-text-sm !zf-text-right zf-break-words">
                        {{ message.content }}
                      </p>
                    </div>
                    <div class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
                      v-if="message.fileMetadata !== undefined">
                      <button class="!zf-text-blue-600 !zf-text-sm !zf-underline"
                        @click="downloadFile(Object.freeze(message.fileMetadata))">
                        {{ message.fileMetadata.name }}
                      </button>
                    </div>
                    <div v-if="message.timestamp !== ''">
                      <p class="zf-italic zf-text-xs zf-text-gray-500">
                        {{ new Date(message.timestamp).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Chat Messages End-->
              <!-- Chat Footer-->
              <ChatFooter :is-typing="isTyping" :send-message-error="sendMessageError" :message-to-send="messageToSend"
                @update:message-to-send="newValue => messageToSend = newValue" :process-upload="processUpload"
                :sending-message="sendingMessage" :send-message="sendMessage" :customer-typing="customerTyping" />
            </div>
            <div class="!zf-w-full !zf-h-full" v-if="confirmCloseChat" :class="{
    '!zf-bg-white': showPostChatSurvey,
    'zf-bg-opaque': !showPostChatSurvey,
  }
    ">
              <!-- Close Confirmation-->
              <div v-if="!showPostChatSurvey" class="zf-text-center  zf-pt-36 zf-pb-8">
                <p class="zf-text-white ">Are you sure you want to end the chat?</p>
                <div class="zf-flex zf-flex-col zf-gap-5 mt-4 zf-justify-center zf-px-16 zf-mt-4">
                  <button
                    class="!zf-px-8 !zf-py-2 zf-rounded-full zf-bg-transparent zf-border-2 zf-border-gray-300 hover:zf-bg-gray-300 zf-text-gray-100 hover:zf-text-black zf-cursor-pointer"
                    @click="endChat(true)">
                    End Chat
                  </button>
                  <button
                    class="!zf-px-8 !zf-py-2 zf-rounded-full zf-bg-transparent zf-border-2 zf-border-gray-300 hover:zf-bg-gray-300 zf-text-gray-100 hover:zf-text-black zf-cursor-pointer"
                    @click="cancelEndChat()">
                    Cancel
                  </button>
                </div>
              </div>
              <template v-else>
                <ChatEnd :email-transcript=emailTranscript
                  @update:email-transcript="newValue => emailTranscript = newValue"
                  :transcript-sent-failed="transcriptSentFailed"
                  @update:transcript-sent-failed="newValue => transcriptSentFailed = newValue"
                  :show-qualtrics-survey="showQualtricsSurvey"
                  @update:show-qualtrics-survey="newValue => showQualtricsSurvey = newValue"
                  :request-email-transcript="requestEmailTranscript" :transcript-sent="transcriptSent"
                  :transcript-sending="transcriptSending" :start-new-chat="startNewChat" :survey-url="surveyUrl"
                  :is-internal="true" />
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
