<script setup>
import {
  OmnichannelChatSDK,
  isCustomerMessage,
} from '@microsoft/omnichannel-chat-sdk';
import { onMounted, onUnmounted, ref, toRaw, defineProps } from 'vue';
import {
  ChatBubbleOvalLeftIcon,
  Bars3BottomRightIcon,
  XMarkIcon,
  MinusIcon,
  ArrowPathIcon
} from '@heroicons/vue/24/solid';

import Login from './components/Login.vue';
import ChatEnd from './components/ChatEnd.vue';
import Message from './components/Message.vue';
import ChatFooter from './components/ChatFooter.vue';
import { MESSAGES, REFRESH_INTERVAL } from './constants';
import messsageSound from './assets/message-received.mp3';
import LoadingThingy from './components/LoadingThingy.vue';
import CustomerSurveyForm from './components/CustomerSurveyForm.vue';
import { addAgentAvailabilityToChannel, departmentMapping, getIdOfQuestionByName, loginUserFlow, extractDataFromMyAccount, fillingPhone, getCookie, buildDataToStartChat, generateFingerPrint, deleteCustomerData, postChatLogsToKafkaTopics, getCustomerSession, printJsonMessage, isJsonMessage } from './rules'


defineProps({
  isInternal: {
    type: Boolean,
    default: false
  }
})

const omnichannelConfig = {
  orgUrl: import.meta.env.VITE_ORG_URL,
  orgId: import.meta.env.VITE_ORG_ID,
  widgetId: import.meta.env.VITE_APP_ID,
};

const chatSDKConfig = {
  telemetry: {
    disable: true, // Disable telemetry
  },
};

const messageNotification = new Audio(messsageSound);
const audioPlayEnabled = ref(false);
const userMuted = ref(false);
const salesLocation = window.location.pathname.includes('sales') ? true : false;
const chatSDK = new OmnichannelChatSDK(omnichannelConfig, chatSDKConfig);
const isLoading = ref(true);
const closedExtra = ref(false);
const showChatWidget = ref(false);
const preChatQuestions = ref([]);
const systemMessage = ref('');
const waiting_to_start = ref(null);
const initializeError = ref(null);
const channelOptions = ref(null);
const preChatSurveyResponses = ref({
  name: '',
  email: '',
  phone: '',
  channel_id: salesLocation ? 'Sales' : '',
  source: window.location.pathname,
  usi: "",
  uuidt: "",
  uuidr: ""
});
const chatMessages = ref([]);
const isTyping = ref(false);
const messageToSend = ref('');
const sendingMessage = ref(false);
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const emailTranscript = ref('');
const confirmCloseChat = ref(false);
const confirmDecisionState = ref(false);
const showPostChatSurvey = ref(false);
const showQualtricsSurvey = ref(false);
const transcriptSentFailed = ref('');
const transcriptSending = ref(false);
const transcriptSent = ref(false);
const allowChat = ref(true);
const salesAgentsAvailable = ref(true);
const chatId = ref(null);
const labelToIdMap = ref({});
const agentName = ref(null);
const surveyUrl = ref(null);
const offset = ref(0);
const customerVerified = ref(false);
const initiatingSurvey = ref(false);
const sendMessageError = ref('');
const deleteError = ref('');

// Mobile-App Customization functions
const isMobileApp = localStorage?.getItem('mobile');

const updateQueues = (queues, utc_now, offset) => {
  const _queues = queues?.map((item) => {
    item = addAgentAvailabilityToChannel(item, utc_now, offset);
    // Hide Chat Widget when sales in unavailable and no active chat. 
    if (item.name === "Sales" && salesLocation && !chatSDK?.chatToken?.chatId) {
      salesAgentsAvailable.value = !item.isUnAvailable;
    }
    if (preChatSurveyResponses.value.channel_id === item.name && item.isUnAvailable) {
      preChatSurveyResponses.value.channel_id = '';
    }
    return item;
  });
  return _queues;
};

const isSessionActive = () => {
  try {
    const userSession = JSON.parse(localStorage?.getItem('user-session'))
    if (userSession) {
      return true;
    } else {
      return false;
    }
  }
  catch (e) {
    return false;
  }
}

const loadChannelOptions = async () => {
  const url = import.meta.env.VITE_CHANNEL_OPTIONS_URL;
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      if (salesLocation && !chatSDK?.chatToken?.chatId) {
        const salesChannelDetails = data?.queues?.find((channel) => channel.name === 'Sales');
        const salesChannelAvailability = addAgentAvailabilityToChannel(salesChannelDetails);
        salesAgentsAvailable.value = !salesChannelAvailability?.isUnAvailable;
      };
      const updatedQueues = updateQueues(data?.queues, data?.utc_now, offset.value);
      channelOptions.value = {
        utc_now: data.utc_now,
        queues: updatedQueues,
      }
    })
    .catch((e) => {
      console.log(e);
    });
};


const customerChatInterval = setInterval(() => {
  let queues = channelOptions?.value?.queues;
  offset.value = offset.value + REFRESH_INTERVAL;
  const utc_now = channelOptions?.value?.utc_now;
  // if(environment !== PRODUCTION){
  if (true) {
    const updatedQueues = updateQueues(queues, utc_now, offset.value);
    channelOptions.value.queues = updatedQueues;
  }
}, REFRESH_INTERVAL);


// const videoCallingSDK = ref(null);
// const chatToken = ref(null);

onMounted(async () => {
  //console.log('called onMounted');
  isLoading.value = true;
  const origin = window.location.origin;
  const hostname = window.location.hostname;
  const currentPath = window.location.pathname;
  if (localStorage?.getItem('mobile')) {
    import('../src/mobile.css');
  }
  try {
    let getChatPathsCall = null
    if (!isMobileApp && hostname !== 'localhost') {
      getChatPathsCall = await fetch(
        `${origin}/helpcenter/GetChatPaths`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
        }
      );
    }

    const getChatPathsResponse = getChatPathsCall ? await getChatPathsCall.json() : {};
    const allowedChatPaths = getChatPathsResponse.allowChatOn ?? [];
    if (isMobileApp) {
      allowChat.value = true;
      console.log(`Chat allowed for my account mobile app`)
    }
    else if (currentPath.includes('/sales')) {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`)
    } else if (currentPath.includes('/myaccount')) {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`)
    }
    else if (allowedChatPaths.length > 0) {
      allowChat.value = allowedChatPaths.some((path) => currentPath === path) || currentPath === '/';
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`)
    }
    else {
      allowChat.value = true;
      console.log(`Chat allowed value for ${currentPath}: ${allowChat.value}`)
    }
  }
  catch (e) {
    console.log(e);
  }

  if (!allowChat.value) {
    isLoading.value = false;
    return;
  }

  await loadChannelOptions();
  await chatSDK.initialize();

  // isLoading.value = false;

  preChatQuestions.value = await chatSDK.getPreChatSurvey();
  const checkPreviousSession = sessionStorage.getItem('MS365ChatSession');
  if (checkPreviousSession) {
    try {
      const optionalParams = {};
      optionalParams.liveChatContext = JSON.parse(checkPreviousSession);
      await chatSDK.startChat(optionalParams);
      chatMessages.value = await chatSDK.getMessages();
      await initializeChatEvents();
      showChatWidget.value = true;
      closedExtra.value = true;
      preChatSurveyResponses.value = JSON.parse(
        sessionStorage.getItem('MS365ChatPreChatSurvey')
      );
      const { conversationId } = await chatSDK.getConversationDetails();
      if (conversationId) {
        await postChatLogsToKafkaTopics("START", preChatSurveyResponses.value, conversationId, channelOptions.value);
      }
      setTimeout(() => {
        document
          .getElementById('chatInputBox')
          ?.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('chatInputBox')?.focus();
      }, 500);
    } catch (e) {
      clearSessionStore();
    }
  }
  if (currentPath.includes("/myaccount")) {
    customerVerified.value = true;
    const data = extractDataFromMyAccount();
    if (data) {
      preChatSurveyResponses.value.name = data.name;
      preChatSurveyResponses.value.email = data.email;
      preChatSurveyResponses.value.phone = fillingPhone(data.phone);
      preChatSurveyResponses.value.usi = data?.billingUSI;
      preChatSurveyResponses.value.uuidt = getCookie('Token');
      //preChatSurveyResponses.value.uuidr = getCookie('RefreshToken');
      //TODO: Talk to team about setting this deafult 
      preChatSurveyResponses.value.channel_id = 'Sales';
    }
  } else {
    const fingerPrint = await generateFingerPrint();
    if (!localStorage.getItem('user-session')) {
      const customerData = await getCustomerSession({ uuidf: fingerPrint });
      if (customerData?.data?.success) {
        preChatSurveyResponses.value.name =
          customerData?.data?.responseObject?.firstName +
          " " +
          customerData?.data?.responseObject?.lastName;
        preChatSurveyResponses.value.email =
          customerData?.data?.responseObject?.email;
        preChatSurveyResponses.value.phone = fillingPhone(
          customerData?.data?.responseObject?.phone
        );
        preChatSurveyResponses.value.usi = customerData?.data?.responseObject?.usi;
        preChatSurveyResponses.value.uuidt =
          customerData?.data?.responseObject?.uuidt;
        preChatSurveyResponses.value.channel_id = "Sales";
        preChatSurveyResponses.value.uuidr =
          customerData?.data?.responseObject?.uuidr;
        localStorage.setItem("user-session", JSON.stringify(customerData?.data?.responseObject));
      }
    }
  }
  isLoading.value = false;
});

onUnmounted(() => {
  //console.log('called onUnmounted');
  clearInterval(customerChatInterval);
});

const toggleMinimise = () => {
  //console.log('Called Toggle Minimise');
  closedExtra.value = !closedExtra.value;
};

// Called when the chat widget is toggled eg: minimise of maximise
const toggleChat = async () => {
  initializeAudio();
  //console.log('Called Toggle Chat');
  if (confirmCloseChat.value) return;

  showChatWidget.value = !showChatWidget.value;
  if (showChatWidget.value) {
    isLoading.value = true;
    closedExtra.value = true;
    //await loadChannelOptions();
    isLoading.value = false;
    if (chatMessages.value.length > 0) {
      setTimeout(() => {
        document
          .getElementById('chatInputBox')
          ?.scrollIntoView({ behavior: 'smooth' });
        document.getElementById('chatInputBox')?.focus();
      }, 500);
    }
  }
};

const initializeChatEvents = async () => {
  await chatSDK.onNewMessage((message) => {
    if (audioPlayEnabled.value) {
      messageNotification.play();
    }
    if (
      chatMessages.value.length === 1 &&
      chatMessages.value[0].id === '00000000-0000-0000-0000-000000000000'
    ) {
      chatMessages.value = [];
    }
    if (waiting_to_start.value !== null) {
      clearTimeout(waiting_to_start.value);
      waiting_to_start.value = null;
    }
    showChatWidget.value = true;
    if (
      chatMessages.value.length === 1 &&
      message.content === chatMessages.value[0].content
    ) {
      return;
    }

    chatMessages.value = [message, ...chatMessages.value];
    setTimeout(() => {
      document
        .getElementById('chatInputBox')
        ?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('chatInputBox')?.focus();
    }, 500);
    if (!isCustomerMessage(message)) {
      agentName.value = message.sender.displayName;
      chatId.value = chatSDK?.chatToken?.chatId;
    }
  });
  await chatSDK.onTypingEvent(() => {
    isTyping.value = true;
    setTimeout(() => {
      isTyping.value = false;
    }, 1000);
  });
  await chatSDK.onAgentEndSession(async () => {
    //alert("Agent ended the session. Please refresh the page to start a new session.")
    surveyUrl.value = await buildSurveyUrl(preChatSurveyResponses);
    clearSessionStore()
    endChat(true);
  });
  // chatToken.value = await chatSDK.getChatToken();
};



const checkAndSyncMessages = async () => {
  chatMessages.value = await chatSDK.getMessages();
  if (chatMessages.value.length === 0) {
    chatMessages.value = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        content: 'Connecting...',
        sender: {
          displayName: '',
        },
        timestamp: new Date().toISOString(),
      },
    ];
    setTimeout(() => {
      document
        .getElementById('chatInputBox')
        ?.scrollIntoView({ behavior: 'smooth' });
      document.getElementById('chatInputBox')?.focus();
    }, 500);
    waiting_to_start.value = setTimeout(async () => {
      chatMessages.value = await chatSDK.getMessages();
      if (chatMessages.value.length === 0) {
        chatMessages.value = [
          {
            id: '00000000-0000-0000-0000-000000000000',
            content: 'Please wait for an agent to join the chat.',
            sender: {
              displayName: '',
            },
            timestamp: new Date().toISOString(),
          },
        ];
      }
    }, 5000);
  }
};

const chatAction = () => {
  //console.log('called chatAction');
  const checkPreviousSession = sessionStorage.getItem('MS365ChatSession');
  if (!checkPreviousSession) {
    if (isSessionActive()) {
      loginUserFlow(preChatSurveyResponses);
      const { preChatResponse, chatContext } = buildDataToStartChat(preChatQuestions.value, preChatSurveyResponses.value);
      startChat(preChatResponse, chatContext);
      showChatWidget.value = true;
    }
  }
  if (salesLocation === true && !showChatWidget.value && !isLoading.value) {
    if (chatSDK.isInitialized && chatSDK?.chatToken?.chatId) {
      toggleChat();
    } else {
      // TODO: For sales we start the chat without prechat questions, check how to proceed with this in chat bots
      startChat();
    }
  } else if (!isLoading.value) {
    toggleChat();
  }
};

// Start Chat on click of start button inside chat or on click of chat icon if in sales page
const startChat = async (preChatResponse, customContext) => {
  transcriptSent.value = false;
  if (!channelOptions.value?.utc_now) {
    return;
  }
  if (salesLocation) {
    const sourceKey = getIdOfQuestionByName(preChatQuestions.value, 'Source');
    customContext = {
      ...customContext,
      RequestType: { value: 'Sales' },
    };
    preChatResponse = {
      ...preChatResponse,
      [sourceKey]: window?.location?.href || 'Unknown',
      Type: 'InputSubmit',
    };
  }
  try {
    initializeAudio(true);
    isLoading.value = true;
    await chatSDK.startChat({
      preChatResponse: preChatResponse,
      customContext: customContext,
      sendDefaultInitContext: true,
    });

    const chatContext = await chatSDK.getCurrentLiveChatContext();
    sessionStorage.setItem('MS365ChatSession', JSON.stringify(chatContext));
    sessionStorage.setItem(
      'MS365ChatPreChatSurvey',
      JSON.stringify(preChatSurveyResponses.value)
    );
    await initializeChatEvents();
    await checkAndSyncMessages();
    if (salesLocation) {
      showChatWidget.value = !showChatWidget.value;
    }
    if (chatMessages.value.length > 0) {
      setTimeout(async () => {
        const { conversationId } = await chatSDK.getConversationDetails({});
        if (conversationId) {
          await postChatLogsToKafkaTopics("START", preChatSurveyResponses.value, conversationId, channelOptions.value);
        }
      }, 6000);
    }
    isLoading.value = false;
    initializeError.value = null;
  } catch (e) {
    isLoading.value = false;
    if (e.message === 'ConversationInitializationFailure') {
      initializeError.value = 'Error occured while initializing chat. Please try again.';
    } else if (e.message === 'WidgetUseOutsideOperatingHour') {
      initializeError.value = 'Agents are not available at this time. Please try after some time';
    }
    console.log(e);
    if (salesLocation === true) {
      toggleChat();
    }
  }
};

const initializeAudio = (unmute = false) => {
  if (!audioPlayEnabled.value) {
    messageNotification.play().then(() => {
      messageNotification.pause();
      messageNotification.currentTime = 0;
      audioPlayEnabled.value = true;
    });
  }
  if (unmute) {
    userMuted.value = false;
  }
  if (!audioPlayEnabled.value) {
    userMuted.value = true;
  }
};

const sendMessage = async (message, metadata = null) => {
  if (messageToSend.value.length === 0 && message.length === 0) {
    return;
  }
  const _message = message || messageToSend.value;
  initializeAudio(false);
  sendingMessage.value = true;
  try {
    const toSend = metadata ? { content: _message, metadata } : { content: _message };
    await chatSDK.sendMessage(toSend);
    chatMessages.value = [
      {
        id: '00000000-0000-0000-0000-000000000000',
        content: _message,
        sender: {
          displayName: 'Customer',
        },
        metadata: metadata,
        timestamp: new Date().toISOString(),
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    messageToSend.value = '';
    setTimeout(() => {
      var scrollContainer = document.getElementById('scrollContainer');
      scrollContainer?.scrollTo(0, scrollContainer.scrollHeight)
      document.getElementById('chatInputBox')?.focus();
    }, 100);
    sendMessageError.value = '';
  }
  catch (e) {
    console.log(e);
    sendMessageError.value = MESSAGES.SEND_MESSAGE_FAILURE;
    sendingMessage.value = false;
  }
};

const customerTyping = async (event) => {
  if (event.key === 'Enter' && messageToSend.value.length > 0) {
    await sendMessage();
  }
};

const logOutAndEndChat = async () => {
  const fingerPrint = await generateFingerPrint();
  const res = await deleteCustomerData({ uuidf: fingerPrint });
  if (res.success) {
    // clear local storage for user-session
    clearLocalStore();
    endChat(true);
    deleteError.value = '';
  } else {
    deleteError.value = "Unable to logout at this time. Please try again later.";
  }
}


// Called when close button is clicked when in form screen
const endChat = async (confirmed = false) => {
  //console.log('called endChatWindow', confirmed);
  const copyOfPreChatSurveyResponse = JSON.parse(JSON.stringify(preChatSurveyResponses.value));
  if (!confirmed) {
    initiatingSurvey.value = true;
    surveyUrl.value = await buildSurveyUrl(preChatSurveyResponses);
    initiatingSurvey.value = false;
  }
  if (confirmed) {
    clearSessionStore();
  }
  if (!salesLocation) {
    preChatSurveyResponses.value.channel_id = '';
  }
  if (chatMessages.value.length === 0) {
    showChatWidget.value = false;
    return;
  }
  confirmCloseChat.value = true;
  confirmDecisionState.value = true;
  if (confirmed) {
    emailTranscript.value = copyOfPreChatSurveyResponse.email;
    showPostChatSurvey.value = true;
  }
};

const cancelEndChat = () => {
  //console.log('called cancelEndChat');
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
};

const cleanFieldsAndValidations = () => {
  //console.log('called cleanFieldsAndValidations');
  preChatSurveyResponses.value.name = '';
  preChatSurveyResponses.value.email = '';
  preChatSurveyResponses.value.phone = '';
};

const clearSessionStore = () => {
  //console.log('called clearSessionStore');
  sessionStorage.removeItem('MS365ChatSession');
  sessionStorage.removeItem('MS365ChatPreChatSurvey');
};

const clearLocalStore = () => {
  //console.log('called clearLocalStore');
  localStorage.removeItem('user-session');
};

const cleanUp = () => {
  //console.log('called cleanUp');
  if (!isSessionActive()) {
    clearSessionStore();
    cleanFieldsAndValidations();
  }
  showChatWidget.value = false;
  closedExtra.value = false;
  chatMessages.value = [];
  confirmCloseChat.value = false;
  confirmDecisionState.value = false;
  showPostChatSurvey.value = false;
  chatId.value = null;
  agentName.value = null;
  surveyUrl.value = null;
  labelToIdMap.value = {};
  initiatingSurvey.value = false;
  clearSurvey();
};

const closeChatWindow = async (endChat = true) => {
  cleanUp();
  try {
    if (salesLocation) {
      preChatSurveyResponses.value.channel_id = 'Sales';
    }
    // End Chat only when transcript is not sent, else end chat is called in requestEmailTranscript
    if (endChat && !transcriptSent.value) {
      const { conversationId } = await chatSDK.getConversationDetails();
      if (conversationId) {
        await postChatLogsToKafkaTopics("END", preChatSurveyResponses.value, conversationId, channelOptions.value);
      }
      transcriptSent.value = false;
      await chatSDK.endChat();
    }
  }
  catch (e) {
    console.log(e);
  }
};

const startNewChat = async (endChat = true) => {
  //console.log('called startNewChat'. endChat);
  cleanUp();
  if (salesLocation) {
    preChatSurveyResponses.value.channel_id = 'Sales';
  }
  // Call end chat only when transcript is not sent, else end chat is called in requestEmailTranscript
  if (!transcriptSent.value) {
    transcriptSent.value = false;
    await chatSDK.endChat();
  }

  setTimeout(() => {
    chatAction();
  }, 400);
};

const downloadFile = (fileMetadata) => {
  chatSDK
    .downloadFileAttachment(toRaw(fileMetadata))
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');

      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = fileMetadata.name;

      // Append link to the body
      document.body.appendChild(link);

      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      // Remove link from body
      document.body.removeChild(link);
    })
    .catch((e) => {
      console.log(e);
      // alert('Unable to download attachment.');
    });
};

const processUpload = async (evt) => {
  const file = evt.target.files[0];
  try {
    sendingMessage.value = true;
    const resp = await chatSDK?.uploadFileAttachment(file);
    //console.log(resp);
    chatMessages.value = [
      {
        id: resp.id,
        ...resp,
      },
      ...chatMessages.value,
    ];
    sendingMessage.value = false;
    evt.target.value = null;
    //console.log(chatMessages.value);
  } catch (e) {
    console.log(e);
    // alert('Unable to upload file.');
  }
};

const requestEmailTranscript = async () => {
  if (!emailPattern.test(emailTranscript.value)) {
    transcriptSentFailed.value = 'Please enter a valid email.';
    return;
  }
  try {
    transcriptSending.value = true;
    transcriptSentFailed.value = '';
    await chatSDK.emailLiveChatTranscript({
      emailAddress: emailTranscript.value,
      attachmentMessage: 'Your Chat Transcript',
    });
    transcriptSending.value = false;
    transcriptSent.value = true;
    await chatSDK.endChat();
  } catch (e) {
    transcriptSending.value = false;
    console.log(e);
    transcriptSentFailed.value = 'Unable to email transcript.';
  }
};

//Set this once chatsdk is initialized
window.triggerOmniChat = (secretKey) => {
  if (secretKey === 'D582223DCFE61') {
    showChatWidget.value = true;
  }
};

window.hasActiveSession = () => {
  return Boolean(chatSDK?.chatToken?.chatId);
}

const completeLoginFlow = () => {
  loginUserFlow(preChatSurveyResponses);
  const { preChatResponse, chatContext } = buildDataToStartChat(preChatQuestions.value, preChatSurveyResponses.value);
  startChat(preChatResponse, chatContext);
}

const buildSurveyUrl = async (preChatSurveyResponses) => {
  const channelId = preChatSurveyResponses.value.channel_id;
  const customerName = preChatSurveyResponses.value.name || "";
  const customerEmail = preChatSurveyResponses.value.email || "";
  const customerPhone = preChatSurveyResponses.value.phone || "";
  let finalNickName = "";
  let finalCenterName = "";
  let finalCrisId = "";
  let finalConversationId = "";
  let conversationNumber = "";
  const location = departmentMapping.find((dept) => dept.dept === channelId)?.deptId || channelId || "";
  if (chatSDK?.chatToken?.chatId) {
    const { conversationId } = await chatSDK.getConversationDetails();
    const url = import.meta.env.VITE_CONVERSATION_AGENT_INFO_URL;
    const getAgentDetails = await fetch(url + '?' + new URLSearchParams({
      "conversation_id": conversationId,
    }), {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const { nickname, center_name, cris_id, conversation_number } = await getAgentDetails.json();
    finalNickName = nickname ? nickname : "";
    finalCenterName = center_name ? center_name : "";
    finalCrisId = cris_id ? cris_id : "";
    finalConversationId = conversationId ? conversationId : "";
    conversationNumber = conversation_number ? conversation_number : "";
  }
  return `https://ziplyfiber.sjc1.qualtrics.com/jfe/form/SV_eqXCFSpkf31O68d?location=${location}&TriggeredAt=${new Date().toISOString()}&CustomerName=${customerName}&CustomerEmail=${customerEmail}&CustomerPhone=${customerPhone}&agent_id=${finalCrisId}&ChatID=${finalConversationId}&Contact%20Center%20Name=${finalCenterName}&ConversationNumber=${conversationNumber}&nickname=${finalNickName}`;
}

const clearSurvey = () => {
  var qualtricsSurvey = document.getElementById('qualtricsSurvey');
  qualtricsSurvey.style.display = 'none';
  if (qualtricsSurvey.childNodes.length > 0) {
    qualtricsSurvey.removeChild(qualtricsSurvey.childNodes[0]);
  }
  showQualtricsSurvey.value = false;
};

if (isMobileApp) {
  toggleChat();
}

const newCustomer = () => {
  channelOptions.value = {
    ...channelOptions.value,
    queues: channelOptions.value.queues.filter((item) =>
      item.name === 'Sales'
    )
  }
  const loginFrame = document.getElementById("loginFrame");
  loginFrame.remove();
  customerVerified.value = true;
}

</script>

<template>
  <!-- Chat Icon Start -->
  <div id="chatWrapper" class="chatWrapper" v-if="salesAgentsAvailable && allowChat">
    <!-- loading spinner for Mobile apps -->
    <div id="mobAppSpinner" v-if="isLoading">
      <div id="loader"></div>
      <p v-if="!isLoading" id="loader-text">
        Please wait while we check for available agents
      </p>
    </div>
    <div id="chatActivator"
      class="!zf-z-[1100] zf-fixed !zf-right-6 !zf-bottom-6 sm:zf-right-12 sm:zf-bottom-12 zf-flex zf-gap-4 zf-items-center">
      <!-- <div
        class="!zf-bg-gray-50 zf-shadow zf-items-center zf-justify-center !zf-px-4 !zf-h-fit !zf-py-2 zf-relative md:zf-flex zf-hidden chat-help"
        v-if="!isLoading && !showChatWidget && !closedExtra">
        <button class="!zf-fill-white !zf-bg-ziply-blue-dark zf-rounded-full zf-absolute -zf-left-2 -zf-top-2 !zf-p-1"
          @click="closedExtra = true">
          <XMarkIcon class="!zf-w-3 !zf-h-3 zf-fill-white" />
        </button>
        <p>Chat With Us</p>
      </div> -->

      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark !zf-bg-ziply-blue md:zf-w-24 md:zf-h-24 md:zf-rounded-[4px]"
        v-if="!showChatWidget" :disaled="isLoading" @click="chatAction()">
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center" v-if="!isLoading">
          <div class="!zf-text-center">
            <ChatBubbleOvalLeftIcon class="zf-w-6 zf-h-6 md:zf-w-12 md:zf-h-12 !zf-fill-gray-100"
              v-if="!showChatWidget" />
            <p class="!zf-text-zf-gray-200 zf-text-3.5 md:zf-text-xl md:zf-flex zf-hidden zf-m-0 zf-text-white">
              Chat
            </p>
          </div>
        </div>
      </div>
      <div
        class="zf-w-12 zf-h-12 zf-rounded-full zf-cursor-pointer zf-shadow-lg hover:zf-bg-ziply-blue-dark zf-circle-button md:zf-w-16 md:zf-h-16 !zf-bg-[#000050]"
        v-if="showChatWidget" :disaled="isLoading" @click="chatAction()">
        <LoadingThingy v-if="isLoading" class="!zf-fill-gray-100" />
        <div class="!zf-flex zf-w-full !zf-h-full !zf-justify-center !zf-items-center" v-if="!isLoading">
          <div class="!zf-text-center">
            <Bars3BottomRightIcon class="zf-w-6 zf-h-6 md:zf-w-12 md:zf-h-12 !zf-fill-gray-100" v-if="showChatWidget" />
          </div>
        </div>
      </div>
    </div>
    <!-- Chat Icon End -->
    <div id="chatContainer"
      class="!zf-z-[1100] zf-fixed zf-bg-white zf-rounded zf-shadow zf-overflow-hidden !zf-w-12/12 !zf-h-[75%] sm:zf-w-[400px] !sm:zf-h-[546px] !zf-right-6 !zf-bottom-[100px] !sm:zf-right-16 !sm:zf-bottom-[126px] !zf-border !zf-border-white"
      v-if="showChatWidget">
      <div id="mobHeader"
        class="!zf-h-[9%] !zf-bg-ziply-blue !zf-text-gray-100 !zf-flex !zf-justify-between !zf-w-full !zf-items-center !zf-px-4 !zf-rounded-t">
        <MinusIcon class="!zf-h-6 !zf-w-6 !zf-mr-3 !zf-fill-gray-100 zf-cursor-pointer" v-if="!confirmDecisionState"
          @click="toggleChat()" />
        <p class="!zf-text-center !zf-w-full !zf-text-lg zf-text-white zf-m-0 !zf-flex !zf-flex-col">
          <span>Chat with Ziply Fiber</span>
        </p>
        <XMarkIcon id="endChatButton" class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 !zf-cursor-pointer zf-end-chat-button"
          v-if="!confirmCloseChat && !initiatingSurvey" @click="endChat()" />
        <ArrowPathIcon class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 zf-end-chat-button rotating" v-if="initiatingSurvey">
        </ArrowPathIcon>
        <XMarkIcon id="closeChatButton"
          class="!zf-h-6 !zf-w-6 !zf-fill-gray-100 !zf-cursor-pointer zf-close-chat-button"
          v-if="showQualtricsSurvey || confirmCloseChat" v-show="showPostChatSurvey" @click="closeChatWindow(true)" />
      </div>
      <!-- Chat Body-->
      <div id="MobConvoBody" class="!zf-h-[91.2%] !zf-w-full !zf-text-[#3c3c3c] !zf-text-justify zf-overflow-hidden">
        <!-- Welcome message and form -->
        <div class="!zf-h-full" id="mobChatBody" v-if="chatMessages?.length === 0">
          <Login v-if="!isSessionActive()" :complete-login-flow="completeLoginFlow" :new-customer="newCustomer"
            id="loginFrame" />
          <div v-if="isSessionActive()" class="zf-w-full zf-h-full zf-flex zf-justify-center zf-items-center">
            <LoadingThingy class="!zf-fill-blue-500" />
          </div>
          <div class="!zf-p-6 !zf-h-full !zf-overflow-scroll zf-no-scrollbar" v-if="customerVerified">
            <p class="!zf-mb-8">
              Your privacy is important to us. We will not ask for SSN, or other
              personal information. To pay online, please go to MyAccount (free) or
              use Pay by Phone ($3.50 fee) at <a class="zf-link" href="tel:866-699-4759">866-699-4759</a>. If you need
              to cancel
              service, please call <a class="zf-link" href="tel:866-699-4759">866-699-4759</a>. To proceed with this
              chat, please
              complete the following and click <strong>Start Chat</strong>.
            </p>
            <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="initializeError">
              {{ initializeError }}
            </p>
            <p v-if="isLoading">Processing...</p>
            <CustomerSurveyForm :channel-options="channelOptions" :startChat="startChat" :salesLocation="salesLocation"
              :preChatSurveyResponses="preChatSurveyResponses" :preChatQuestions="preChatQuestions"
              :is-loading="isLoading" :is-internal="isInternal" v-if="channelOptions" />
          </div>
        </div>
        <template v-else>
          <div class="!zf-flex zf-flex-col !zf-gap-2 !zf-h-full" v-if="!confirmCloseChat">
            <!-- Chat messages -->
            <div v-if="isMobileApp" id="mobBtnWrapper">
              <p id="mobCloseChatButton" @click="endChat()">END CHAT</p>
            </div>
            <div id="scrollContainer"
              class="!zf-flex !zf-flex-col-reverse !zf-h-[90%] !zf-px-4 !zf-py-2 !zf-overflow-scroll zf-no-scrollbar">
              <div v-for="(message, idx) in chatMessages" :key="message.id" class="!zf-flex !zf-flex-col !zf-gap-1">
                <div class="!zf-flex !zf-flex-col !zf-items-start" v-if="!isCustomerMessage(message)">
                  <div class="!zf-text-ziply-blue !zf-flex !zf-justify-center !zf-items-center">
                    <p class="!zf-text-sm !zf-text-ziply-blue !zf-font-normal">
                      {{ message.sender.displayName }}
                    </p>
                  </div>
                  <Message :message="message" :next-message="(idx > 0) ? chatMessages[idx - 1] : null"
                    v-if="message.content !== ''"
                    @update:message-on-click="(newValue, metaData) => sendMessage(newValue, metaData)" />
                  <div class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
                    v-if="message.fileMetadata !== undefined">
                    <button class="!zf-text-blue-600 !zf-text-sm zf-underline"
                      @click="downloadFile(Object.freeze(message.fileMetadata))">
                      {{ message.fileMetadata.name }}
                    </button>
                  </div>
                  <div v-if="message.timestamp !== ''">
                    <p class="zf-italic zf-text-xs zf-text-gray-500">
                      {{ new Date(message.timestamp).toLocaleString() }}
                    </p>
                  </div>
                </div>
                <div class="!zf-flex !zf-flex-col !zf-items-end" v-else>
                  <div class="!zf-text-slate-800 !zf-rounded-full !zf-flex !zf-justify-center !zf-items-center">
                    <p class="!zf-text-sm !zf-font-normal !zf-text-gray-600">
                      {{ preChatSurveyResponses.name }}
                    </p>
                  </div>
                  <div class="!zf-bg-gray-200 !zf-rounded-lg !zf-p-2" v-if="message.content !== '' &&
    typeof message.content !== 'object'
    ">
                    <p class="!zf-text-gray-800 !zf-text-sm !zf-text-right zf-break-words">
                      <span v-if="isJsonMessage(message.content)" v-html="printJsonMessage(message.content)">

                      </span>
                      <template v-else>
                        {{ message.content }}
                      </template>
                    </p>
                  </div>
                  <div class="!zf-bg-gray-100 !zf-rounded-lg !zf-px-4 !zf-py-2"
                    v-if="message.fileMetadata !== undefined">
                    <button class="!zf-text-blue-600 !zf-text-sm !zf-underline"
                      @click="downloadFile(Object.freeze(message.fileMetadata))">
                      {{ message.fileMetadata.name }}
                    </button>
                  </div>
                  <div v-if="message.timestamp !== ''">
                    <p class="zf-italic zf-text-xs zf-text-gray-500">
                      {{ new Date(message.timestamp).toLocaleString() }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- Chat Messages End-->
            <!-- Chat Footer-->
            <ChatFooter :is-typing="isTyping" :send-message-error="sendMessageError" :system-message="systemMessage"
              :message-to-send="messageToSend" @update:message-to-send="newValue => messageToSend = newValue"
              :process-upload="processUpload" :sending-message="sendingMessage" :send-message="sendMessage"
              :customer-typing="customerTyping" />
          </div>
          <div class="!zf-w-full !zf-h-full" v-if="confirmCloseChat" :class="{
    '!zf-bg-white': showPostChatSurvey,
    'zf-bg-opaque': !showPostChatSurvey,
  }">
            <!-- Close Confirmation-->
            <div v-if="!showPostChatSurvey" class="zf-text-center zf-pt-36 zf-pb-8">
              <p class="!zf-text-red-400 !zf-mb-3 !zf-text-sm zf-p-0" v-if="deleteError !== ''">
                {{ deleteError }}
              </p>
              <p class="zf-text-white">Are you sure you want to end the chat?</p>
              <div class="zf-flex zf-flex-col zf-gap-5 zf-mt-4 zf-justify-center zf-px-16">
                <button
                  class="!zf-px-8 !zf-py-2 zf-rounded-full zf-bg-transparent zf-border-2 zf-border-gray-300 hover:zf-bg-gray-300 zf-text-gray-100 hover:zf-text-black zf-cursor-pointer"
                  @click="endChat(true)">
                  End Chat
                </button>
                <button
                  class="!zf-px-8 !zf-py-2 zf-rounded-full zf-bg-transparent zf-border-2 zf-border-gray-300 hover:zf-bg-gray-300 zf-text-gray-100 hover:zf-text-black zf-cursor-pointer"
                  @click="logOutAndEndChat()">
                  Logout and End Chat
                </button>
                <button
                  class="!zf-px-8 !zf-py-2 zf-rounded-full zf-bg-transparent zf-border-2 zf-border-gray-300 hover:zf-bg-gray-300 zf-text-gray-100 hover:zf-text-black zf-cursor-pointer"
                  @click="cancelEndChat()">
                  Cancel
                </button>
              </div>
            </div>
            <template v-else>
              <ChatEnd :email-transcript=emailTranscript
                @update:email-transcript="newValue => emailTranscript = newValue"
                :transcript-sent-failed="transcriptSentFailed"
                @update:transcript-sent-failed="newValue => transcriptSentFailed = newValue"
                :show-qualtrics-survey="showQualtricsSurvey"
                @update:show-qualtrics-survey="newValue => showQualtricsSurvey = newValue"
                :request-email-transcript="requestEmailTranscript" :transcript-sent="transcriptSent"
                :transcript-sending="transcriptSending" :start-new-chat="startNewChat" :survey-url="surveyUrl"
                :is-internal="false" :sales-location="salesLocation" :sales-agents-available="salesAgentsAvailable" />
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>