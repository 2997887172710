
<script setup>
import { defineProps, ref, defineEmits } from 'vue'
const props = defineProps({
    showQualtricsSurvey: Boolean,
    requestEmailTranscript: Function,
    transcriptSent: Boolean,
    transcriptSending: Boolean,
    transcriptSentFailed: String,
    emailTranscript: String,
    startNewChat: Function,
    surveyUrl: String,
    isInternal: Boolean,
    salesLocation: Boolean,
    salesAgentsAvailable: Boolean
})

const emit = defineEmits(['update:emailTranscript', 'update:showQualtricsSurvey', 'update:transcriptSentFailed'])

const initiateSurvey = async () => {
    emit('update:showQualtricsSurvey', true);
    const qualtricsSurvey = document.getElementById('qualtricsSurvey');
    const iframe = document.createElement('iframe');
    iframe.src = props.surveyUrl;
    iframe.width = '100%';
    iframe.height = '100%';
    iframe.className = '!w-full !h-full';
    qualtricsSurvey.appendChild(iframe);
    qualtricsSurvey.style.display = 'block';
};

const emailKeyUp = () => {
    emit('update:transcriptSentFailed', '')
};
const canTriggerNewChat = ref(true);

if(!props.isInternal && props.salesLocation && !props.salesAgentsAvailable) {
    canTriggerNewChat.value = false;
}

</script>
<template>
    <div class="!zf-grid !zf-grid-cols-1 !zf-mb-3 !zf-p-8" v-if="!showQualtricsSurvey">
        <form method="post" @submit.prevent="requestEmailTranscript()" v-if="!transcriptSent">
            <div class="!zf-text-left zf-mb-3">
                <label for="email_chat_transcript" class="zf-block zf-mb-3 zf-leading-6">Thank you for chatting with us. To
                    receive a transcript
                    of this chat, please enter and submit your
                    <strong>email address</strong>.</label>
                <input type="email" :value="emailTranscript" @keyup.prevent="emailKeyUp()"
                    @input="$emit('update:emailTranscript', $event.target.value)" :class="{
                        '!zf-border !zf-text-red-700 !zf-border-red-700':
                            transcriptSentFailed !== '',
                    }" id="email_chat_transcript" class="!zf-w-full !zf-rounded-lg !zf-bg-gray-100 !zf-px-4 !zf-py-2"
                    required />
                <p class="!zf-w-full zf-text-center zf-text-red-700" v-if="transcriptSentFailed !== ''">
                    {{ transcriptSentFailed }}
                </p>
            </div>
            <div class="zf-flex zf-justify-center">
                <button type="submit"
                    class="!zf-px-2 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                    :class="{ 'animate-pulse': transcriptSending }" :disabled="transcriptSending">
                    <template v-if="!transcriptSending">SEND TRANSCRIPT</template>
                    <template v-else>Sending...</template>
                </button>
            </div>
        </form>
        <div class="!zf-text-left !zf-mb-3" v-if="transcriptSent">
            <p>Transcript sent to {{ emailTranscript }}</p>
        </div>
        <div class="!zf-border-t !zf-border-ziply-blue !zf-mt-3 !zf-pt-3 !zf-gap-4">
            <p class="zf-block !zf-mb-3 zf-leading-6" v-if="!isInternal" >
                We appreciate hearing feedback and invite you to take a
                brief survey.
            </p>
            <div class="zf-btn-wrapper">
                <button
                    class="!zf-px-2 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                    v-if="!isInternal"
                    @click="initiateSurvey()">
                    BEGIN SURVEY
                </button>
                <button
                    class="!zf-px-2 !zf-mt-4 !zf-py-2 !zf-w-full !zf-rounded-full zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark !zf-text-gray-100 !zf-cursor-pointer !zf-font-bold !zf-leading-6"
                    :disabled="!canTriggerNewChat"
                    :title="!canTriggerNewChat === 0 ? 'No agents available' : ''"
                    :class="{
                        'zf-opacity-50 zf-cursor-not-allowed': !canTriggerNewChat,
                    }" 
                    @click="startNewChat()">
                    START NEW CHAT
                </button>
            </div>
        </div>
    </div>
    <div class="!zf-w-full !zf-h-full" id="qualtricsSurvey" ref="qualtricsSurvey" style="display: none"></div>
</template>