import moment from "moment-timezone";
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import { UAParser } from "ua-parser-js";

import { UNAVAILABLE_REASON, CUSTOMER_SESSION_API_URL } from "./constants.js";

export const departmentMapping = [
  {
    dept: "Sales",
    deptId: "146294496063627846",
  },
  {
    dept: "Customer Service",
    deptId: "146210741310792512",
  },
  {
    dept: "Technical Service",
    deptId: "146210740591606181",
  },
  {
    dept: "Collections",
    deptId: "4326258904527478102",
  },
];

export const getIdOfQuestionByName = (preChatQuestions, name) => {
  const id = preChatQuestions.body.find(
    (item) =>
      (item.type === "Input.Text" || item.type === "Input.ChoiceSet") &&
      JSON.parse(item.id).Name === name
  )?.id;
  return id;
};

export const addAgentAvailabilityToChannel = (
  _item = {},
  utcTimeNow = "",
  offset = 1
) => {
  let item = { ..._item };
  let message = "";
  let agentAvailKey =
    "agent_available" in item ? "agent_available" : "agentAvailable";
  if (item[agentAvailKey] === undefined) {
    agentAvailKey = "agentAvailability";
  }
  const operationHoursKey =
    "operation_hours" in item ? "operation_hours" : "operatingHours";
  const conditionOne = item[agentAvailKey]
    ? item[agentAvailKey] === false
    : true;
  let conditionTwo =
    item[operationHoursKey] === null || item[operationHoursKey] === "";
  if (!conditionTwo) {
    const operatingHours = item[operationHoursKey][0] ?? {};
    const { Start, End } = operatingHours;
    if (Start && End) {
      let currentTime = moment().tz("US/Pacific");
      if (utcTimeNow) {
        currentTime = moment(utcTimeNow)
          .add(offset / (1000 * 60), "minutes")
          .tz("US/Pacific");
      }
      const isAvailable = moment(currentTime).isBetween(
        moment(Start),
        moment(End)
      );
      conditionTwo = !isAvailable;
    } else {
      conditionTwo = true;
    }
  }
  if (conditionTwo) {
    message =
      item?.operation_hours_human ||
      item?.operationHoursHuman ||
      "Please contact us during business hours.";
  } else if (conditionOne) {
    message = "No Agents Available, Try after sometime.";
  }
  item.message = message;
  item.isUnAvailable = conditionOne || conditionTwo || false;
  item.reason = conditionTwo
    ? UNAVAILABLE_REASON.OOH
    : UNAVAILABLE_REASON.NO_AGENTS;
  return item;
};

export const extractDataFromMyAccount = () => {
  const localStorage = window.localStorage;
  let userInfo = null;
  try {
    userInfo = JSON.parse(localStorage.getItem("userInfo"));
  } catch (e) {
    console.log(e);
  }
  return userInfo;
};

export const buildOOHMessage = (data) => {
  let humanMessage = "";
  if (data && data?.availability && data?.availability.length > 0) {
    humanMessage = "\nAvailability Timings:";
    data.availability.forEach((item) => {
      humanMessage = `${humanMessage}\n${item.from} - ${item.to} from ${item.time}`;
    });
  }
  return humanMessage;
};

export const loadChannelDetails = async (url, appId) => {
  try {
    let res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        app_id: appId,
      }),
    });
    res = await res.json();
    return {
      success: true,
      data: res?.queues[0],
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const loginWithFirebase = async (email, password) => {
  try {
    const firebaseUrl = import.meta.env.VITE_FIREBASE_LOGIN_URL;
    const firebaseKey = import.meta.env.VITE_FIREBASE_LOGIN_KEY;
    const res = await fetch(`${firebaseUrl}?key=${firebaseKey}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        returnSecureToken: true,
        email: email,
        password: password,
        clientType: "CLIENT_TYPE_WEB",
      }),
    });
    const data = await res.json();
    return {
      success: true,
      data,
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const getCustomerDetails = async (email, token) => {
  try {
    const url = import.meta.env.VITE_MYACCOUNT_CUSTOMER_CONTACT_URL;
    const apiKey = import.meta.env.VITE_MYACCOUNT_API_KEY;
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        apikey: `${apiKey}`,
      },
      body: JSON.stringify({
        query: `
          query customerContact($email: String!) {
            customerContact(email: $email) {
              givenAndFamilyName {
                givenName
                familyName
              }
              telephoneContact {
                telephoneNumber
              }
              emailContact {
                emailAddress
              }
              linkedAccountNumbers {
                accountNumber
                usi
              }
            }
          }
          `,
        variables: {
          email: email,
        },
      }),
    });
    const data = await res.json();
    return {
      success: true,
      data,
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const getCustomerSession = async (data) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_CUSTOMER_SESSION_API_URL}/extract`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          ...data,
        }),
      }
    );
    const resData = await response.json();
    return {
      success: true,
      data: resData,
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const deleteCustomerData = async (data) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_CUSTOMER_SESSION_API_URL}/delete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          ...data,
        }),
      }
    );
    const resData = await response.json();
    return {
      success: true,
      data: resData,
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const saveCustomerSession = async (data) => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_CUSTOMER_SESSION_API_URL}/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          ...data,
        }),
      }
    );
    const resData = await response.json();
    return {
      success: true,
      data: resData,
    };
  } catch (e) {
    return {
      success: false,
      message: e.message || "Something went wrong",
    };
  }
};

export const generateFingerPrint = async () => {
  const fingerprint = await getCurrentBrowserFingerPrint();
  return fingerprint;
};

export const loginUserFlow = async (preChatSurveyResponses) => {
  const userData = localStorage.getItem("user-session");
  if (userData) {
    const customerData = JSON.parse(userData);
    preChatSurveyResponses.value.name = customerData?.name
      ? customerData?.name
      : customerData?.firstName + " " + customerData?.lastName;
    preChatSurveyResponses.value.email = customerData?.email;
    preChatSurveyResponses.value.phone = fillingPhone(customerData?.phone);
    preChatSurveyResponses.value.usi = customerData?.usi;
    preChatSurveyResponses.value.uuidt = customerData?.uuidt;
    //TODO: Talk to team about this. This is hardcoded for now.
    preChatSurveyResponses.value.channel_id = "Sales";
    preChatSurveyResponses.value.uuidr = customerData?.uuidr;
    return true;
  } else {
    let fingerprint = await generateFingerPrint();
    const customerData = await getCustomerSession({ uuidf: fingerprint });
    if (customerData?.data?.success) {
      preChatSurveyResponses.value.name =
        customerData?.data?.responseObject?.firstName +
        " " +
        customerData?.data?.responseObject?.lastName;
      preChatSurveyResponses.value.email =
        customerData?.data?.responseObject?.email;
      preChatSurveyResponses.value.phone = fillingPhone(
        customerData?.data?.responseObject?.phone
      );
      preChatSurveyResponses.value.usi =
        customerData?.data?.responseObject?.usi;
      preChatSurveyResponses.value.uuidt =
        customerData?.data?.responseObject?.uuidt;
      //TODO: Talk to team about this. This is hardcoded for now.
      preChatSurveyResponses.value.channel_id = "Sales";
      preChatSurveyResponses.value.uuidr =
        customerData?.data?.responseObject?.uuidr;
      return true;
    } else {
      return false;
    }
  }
};

export const fillingPhone = (phone) => {
  let value = phone;
  value = value.replace(/\D/g, "");
  if (value.length <= 3) {
    phone_input_error.value = "";
  } else if (value.length <= 6) {
    value = "(" + value.substring(0, 3) + ") " + value.substring(3);
  } else {
    value =
      "(" +
      value.substring(0, 3) +
      ") " +
      value.substring(3, 6) +
      "-" +
      value.substring(6, 10);
  }
  return value;
};

export function getCookie(key) {
  let b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export const buildDataToStartChat = (
  preChatQuestions,
  preChatSurveyResponses
) => {
  const nameKey = getIdOfQuestionByName(preChatQuestions, "Name");
  const emailKey = getIdOfQuestionByName(preChatQuestions, "Email");
  const phoneKey = getIdOfQuestionByName(preChatQuestions, "Phone");
  const sourceKey = getIdOfQuestionByName(preChatQuestions, "Source");
  const preChatResponse = {
    Type: "InputSubmit",
  };
  const chatContext = {
    RequestType: { value: preChatSurveyResponses.channel_id },
    usi: {
      value: preChatSurveyResponses.usi ? preChatSurveyResponses.usi : "",
    },
    uuidt: {
      value: preChatSurveyResponses.uuidt ? preChatSurveyResponses.uuidt : "",
    },
    uuidr: {
      value: preChatSurveyResponses.uuidr ? preChatSurveyResponses.uuidr : "",
    },
  };
  preChatResponse[nameKey] = preChatSurveyResponses.name;
  preChatResponse[emailKey] = preChatSurveyResponses.email;
  preChatResponse[phoneKey] = preChatSurveyResponses.phone.replace(
    /[^0-9]/g,
    ""
  );
  preChatResponse[sourceKey] = window.location.href || "Unknown";
  return { preChatResponse, chatContext };
};

export const extractDataFromSalesPages = (params) => {
  const sessionStorage = window.sessionStorage;
  let data = {
    custom_url: sessionStorage.getItem("currentRoute"),
    custom_address: "",
    custom_page_name: params.pageName,
    custom_service_type: "",
    custom_product_selected: "",
    custom_orignating_source: "",
    custom_SAM_Control_NO: "",
    initial_question: "",
    custom_addOnList: "",
    custom_promotion: "",
    name: "",
    email: "",
    phone: "",
    custom_appointment_selected: "",
  };

  if (params.address && sessionStorage.getItem("address")) {
    data.custom_address = sessionStorage.getItem("address");
    // if (sessionStorage.getItem('unitno')) {
    //   data.custom_address = data.custom_address + '#' + sessionStorage.getItem('unitno');
    // }
  }
  if (params.serviceType && sessionStorage.getItem("productData")) {
    const productData = JSON.parse(sessionStorage.getItem("productData"));
    if (
      productData.body &&
      productData.body.content &&
      productData.body.content.response &&
      productData.body.content.response.products &&
      productData.body.content.response.products.length > 0
    ) {
      data.custom_service_type =
        productData.body.content.response.products[0].product_type;
    }
  }
  if (params.customerInfo && sessionStorage.getItem("customerInfo")) {
    const customerInfo = JSON.parse(sessionStorage.getItem("customerInfo"));
    data.name = customerInfo
      ? customerInfo.firstName + " " + customerInfo.lastName
      : "";
    data.email = customerInfo ? customerInfo.email : "";
    data.phone = customerInfo ? customerInfo.cellphone : "";
  }
  if (params.productSelected && sessionStorage.getItem("productSelected")) {
    const productSelected = JSON.parse(
      sessionStorage.getItem("productSelected")
    );
    data.custom_product_selected =
      "Product_name : " +
      productSelected.productName +
      " , " +
      "Product_speed : " +
      productSelected.eligibility_criteria +
      " , " +
      "Product_type :" +
      productSelected.product_type +
      " , " +
      "Product_line : " +
      productSelected.product_line +
      " , " +
      "Service_type : " +
      productSelected.service_type +
      " , " +
      "Product_SKU : " +
      productSelected.sku;
  }
  if (params.addOnList) {
    const addOnList = [];
    const cartDetails = JSON.parse(sessionStorage.getItem("cartResponse")).cart;
    if (
      cartDetails &&
      cartDetails.ItemsList &&
      cartDetails.ItemsList.length > 0
    ) {
      cartDetails.ItemsList.forEach((element) => {
        if (element.Items && Object.keys(element.Items).length > 0) {
          Object.values(element.Items).forEach((value) => {
            if (
              value.ProductCatalogInformation &&
              value.ProductCatalogInformation.Price &&
              value.ProductCatalogInformation.Price.Quantity &&
              value.ProductCatalogInformation.Price.Quantity.Amount &&
              value.ProductCatalogInformation.Price.Quantity.Amount > 0
            ) {
              addOnList.push(JSON.stringify(value));
            }
          });
        }
      });
    }
    // const phoneAddon = sessionStorage.getItem('phoneAddon');
    // const rokuAddon = sessionStorage.getItem('rokuAddon');
    // const wifiAddon = sessionStorage.getItem('wifiAddon');
    // if (installAddon) {
    //   addOnList.push({ 'InstallAddon': JSON.parse(installAddon) });
    // }
    // if (phoneAddon) {
    //   addOnList.push({ 'phoneAddon': JSON.parse(phoneAddon) });
    // }
    // if (rokuAddon) {
    //   addOnList.push({ 'rokuAddon': JSON.parse(rokuAddon) });
    // }
    // if (wifiAddon) {
    //   addOnList.push({ 'wifiAddon': JSON.parse(wifiAddon) });
    // }

    data.custom_addOnList = addOnList.toString();
  }
  if (
    params.selAppointment &&
    sessionStorage.getItem("subscriptionCustomerData")
  ) {
    const appointment_selected = JSON.parse(
      sessionStorage.getItem("subscriptionCustomerData")
    );
    data.custom_appointment_selected =
      appointment_selected.scheduleDate +
      " " +
      appointment_selected.scheduleTime;
  }
  if (params.selAppointment && sessionStorage.getItem("selAppointment")) {
    const appSelected = JSON.parse(sessionStorage.getItem("selAppointment"));
    data.custom_appointment_selected =
      appSelected.date + " " + appSelected.timing;
  }
  data.custom_orignating_source = "SALES_BUY_FLOW";
  data.custom_SAM_Control_NO = "";
  data.initial_question = "";
  data.custom_promotion = "";
  return data;
};

export const getBuildDataForSalesPage = () => {
  const path = window.location.pathname;
  if (path.includes("/servicecheckresults")) {
    data = extractDataFromSalesPages({
      pageName: "Product selection page",
      address: true,
      serviceType: true,
    });
    // data.custom_page_name = 'Product selection page';
  } else if (path.includes("/secservicecheck")) {
    data = extractDataFromSalesPages({
      pageName: "Secondary address selection page",
      address: true,
      serviceType: true,
    });
    // data.custom_page_name = 'Product selection page';
  } else if (path.includes("/ordercustomize")) {
    data = extractDataFromSalesPages({
      pageName: "Add-Ons configuration page",
      address: true,
      serviceType: true,
      productSelected: true,
    });
    // data.custom_page_name = 'Add-Ons configuration page';
  } else if (path.includes("/customerinfo")) {
    data = extractDataFromSalesPages({
      pageName: "Customer Information page",
      address: true,
      serviceType: true,
      addOnList: true,
      productSelected: true,
    });
    // data.custom_page_name = 'Customer Information page';
  } else if (
    path.includes("/ordersubmit") ||
    path.includes("/servicerequest")
  ) {
    data = extractDataFromSalesPages({
      pageName: "Schedule appointment page",
      address: true,
      serviceType: true,
      addOnList: true,
      customerInfo: true,
      productSelected: true,
    });
    // data.custom_page_name = 'Schedule page';
  } else if (path.includes("/orderconfirm")) {
    data = extractDataFromSalesPages({
      pageName: "Confirmation Page",
      address: true,
      serviceType: true,
      addOnList: true,
      customerInfo: true,
      selAppointment: true,
      productSelected: true,
    });
    // data.custom_page_name = 'Schedule page';
  }
  // else if (path.includes('/servicerequest')) {
  //   data = extractDataFromSalesPages({'pageName': 'Schedule page', 'address': true, 'serviceType': true, 'addOnList': true, 'customerInfo': true, 'selAppointment': true, 'productSelected': true});
  //   // data.custom_page_name = 'Schedule page';
  // }
  else if (path.includes("/sales")) {
    data = extractDataFromSalesPages({ pageName: "Address search page" });
    // data.custom_page_name = 'Address search page';
  }
  return data;
};

export const extractDataFromIDC = () => {
  const localStorage = window.localStorage;
  let user = null;
  try {
    user = JSON.parse(localStorage.getItem("Authentication"));
  } catch (e) {
    console.log(e);
  }
  return user;
};

const detectBrowserAndDevice = () => {
  const userAgent = window.navigator.userAgent;
  const isMobile = /Mobi/i.test(userAgent);
  const screenWidth = window.screen.width;
  let device = "";
  let browser = "";
  // Detect Device
  if (isMobile) {
    device = "Mobile";
  } else if (screenWidth < 768) {
    device = "Tablet";
  } else {
    device = "Desktop/Laptop";
  }
  // Detect Browser
  const parser = new UAParser();
  const userAgentInfo = parser.getResult();
  browser = userAgentInfo.browser.name;
  return { browser, device };
};

export const postChatLogsToKafkaTopics = async (
  event,
  preChatSurveyResponses,
  conversationId,
  channelOptions
) => {
  const { browser, device } = detectBrowserAndDevice();
  const url = import.meta.env.VITE_KAFKA_CUSTOMER_EVENTS;
  await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      topic: "events.customer360",
      messages: [
        {
          value: {
            type: event === "START" ? "Start Chat" : "End Chat",
            name: preChatSurveyResponses.name,
            email: preChatSurveyResponses.email,
            phone: preChatSurveyResponses.phone,
            [event === "START" ? "startedAt" : "endedAt"]:
              new Date().toISOString(),
            usi: preChatSurveyResponses.usi,
            conversationId,
            queue: channelOptions.queues,
            browser,
            device,
          },
        },
      ],
    }),
  });
};

export const isJsonMessage = (msg) => {
  try {
    const val = JSON.parse(msg);
    return "value" in val;
  } catch (e) {
    return false;
  }
};

export const printJsonMessage = (msg) => {
  try {
    const val = JSON.parse(msg).value;
    let toRet = "";
    for (let key in val) {
      if (!key.startsWith("action")) {
        toRet += `${key} : ${val[key]}<br>`;
      }
    }
    return toRet;
  } catch (e) {
    console.log(e);
    return null;
  }
};
