<script setup lang="ts">
import { defineProps, ref } from 'vue'
import { getIdOfQuestionByName, extractDataFromMyAccount, buildDataToStartChat } from '../rules'
const props = defineProps<{
    isInternal: boolean
    channelOptions: any,
    preChatSurveyResponses: any,
    preChatQuestions: any,
    isLoading: boolean,
    startChat: any,
}>()

const namePattern = /^[a-zA-Z\s]+$/;
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const name_input_error = ref('');
const email_input_error = ref('');
const phone_input_error = ref('');
const highlight_channels = ref(false);
const currentPath = window.location.pathname;
const isMobileApp = localStorage?.getItem('mobile');

const _channelOptions = props.channelOptions.queues;

const validateForm = () => {
    if (props.preChatSurveyResponses.name.length === 0) {
        name_input_error.value = 'Please enter your name.';
        return
    } else if (props.preChatSurveyResponses.name.length !== 0 && props.preChatSurveyResponses.name.length < 3) {
        name_input_error.value = 'Please enter a valid name.';
        return;
    }

    if (props.preChatSurveyResponses.email.length === 0) {
        email_input_error.value = 'Please enter your email.';
        return;
    } else if (props.preChatSurveyResponses.email.length !== 0 && !emailPattern.test(props.preChatSurveyResponses.email)) {
        email_input_error.value = 'Please enter a valid email.';
        return;
    }
    if (props.preChatSurveyResponses.phone.length === 0) {
        phone_input_error.value = 'Please enter your phone number.'
        return;
    } else if (props.preChatSurveyResponses.phone.length !== 0) {
        if (
            props.preChatSurveyResponses.phone.length > 0 &&
            props.preChatSurveyResponses.phone.length !== 14
        ) {
            phone_input_error.value = 'Please enter a valid phone number.';
            return;
        }
    }
    if (
        props.preChatSurveyResponses.channel_id === '' &&
        _channelOptions.length > 0
    ) {
        highlight_channels.value = true;
        return
    }
    // Reset input errors
    name_input_error.value = '';
    email_input_error.value = '';
    phone_input_error.value = '';
    highlight_channels.value = false;
    const { preChatResponse, chatContext } = buildDataToStartChat(props.preChatQuestions, props.preChatSurveyResponses);
    props.startChat(preChatResponse, chatContext);
}
const fillingPhone = () => {
    let value = props.preChatSurveyResponses.phone;
    value = value.replace(/\D/g, '');
    if (value.length <= 3) {
        props.preChatSurveyResponses.phone = value;
        phone_input_error.value = '';
    } else if (value.length <= 6) {
        props.preChatSurveyResponses.phone =
            '(' + value.substring(0, 3) + ') ' + value.substring(3);
    } else {
        props.preChatSurveyResponses.phone =
            '(' +
            value.substring(0, 3) +
            ') ' +
            value.substring(3, 6) +
            '-' +
            value.substring(6, 10);
    }
};

function validateName(event) {
    var charCode = event.keyCode;
    if (
        (charCode >= 97 && charCode <= 122) ||
        (charCode >= 65 && charCode <= 90) ||
        charCode === 32
    ) {
        return true;
    } else {
        event.preventDefault();
        return false;
    }
}

function selectChannel(channel) {
    props.preChatSurveyResponses.channel_id = channel;
    highlight_channels.value = false;
}

if (currentPath.includes("/myaccount") || isMobileApp) {
    const data = extractDataFromMyAccount();
    if (data) {
        props.preChatSurveyResponses.name = data.name;
        props.preChatSurveyResponses.email = data.email;
        props.preChatSurveyResponses.phone = data.phone;
        fillingPhone();
    }

}

</script>

<template>
    <form method="post" @submit.prevent="validateForm()" v-if="!isInternal">
        <input type="text" v-model.trim="preChatSurveyResponses.name" placeholder="Name*" v-on:keypress="validateName"
            @keyup.prevent="name_input_error = ''" :class="{
        '!zf-border !zf-border-red-700 zf-mb-0': name_input_error !== '',
    }" class="!zf-w-full zf-rounded !zf-mb-4 !zf-px-4 !zf-bg-gray-100 !zf-py-2 !zf-h-12" />
        <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="name_input_error !== ''">
            {{ name_input_error }}
        </p>
        <input type="text" v-model.trim="preChatSurveyResponses.email" @keyup.prevent="email_input_error = ''" :class="{
        'zf-border zf-border-red-700 zf-mb-0': email_input_error !== '',
    }" placeholder="Email*" class="!zf-w-full zf-rounded !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12" />
        <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="email_input_error !== ''">
            {{ email_input_error }}
        </p>
        <input type="tel" @keyup.prevent="fillingPhone()" @keydown="phone_input_error = ''"
            v-model.trim="preChatSurveyResponses.phone" :class="{
        '!zf-border !zf-border-red-700 zf-mb-0': phone_input_error !== '',
    }" placeholder="Phone*" class="!zf-w-full zf-rounded !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12" />
        <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="phone_input_error !== ''">
            {{ phone_input_error }}
        </p>
        <div class="!zf-mb-3 block">
            <button v-for="item in _channelOptions" @click="() => selectChannel(item.name)" type="button"
                :disabled="item.isUnAvailable" :title="item.message"
                class="!zf-px-4 !zf-py-2 !zf-m-1 zf-rounded-full zf-border-ziply-blue !zf-border zf-text-ziply-blue"
                :class="{
        '!zf-bg-ziply-blue-dark !zf-text-[white] ':
            preChatSurveyResponses.channel_id === item.name,
        '!zf-border-red-600 !zf-text-red-600 ':
            highlight_channels &&
            preChatSurveyResponses.channel_id !== item.name,
        'zf-opacity-50 zf-cursor-not-allowed': item.isUnAvailable,
    }">
                {{ item.name }}
            </button>
        </div>
        <p v-if="highlight_channels" class="!zf-text-red-700">
            Choose a department
        </p>
        <input type="submit" value="START CHAT" :disabled="_channelOptions.length === 0"
            :title="_channelOptions.length === 0 ? 'No agents available' : ''"
            class="!zf-px-8 !zf-py-2 zf-rounded-full !zf-bg-ziply-blue hover:zf-bg-ziply-blue-dark zf-text-gray-100 zf-cursor-pointer zf-font-bold"
            :class="{
        'zf-opacity-50 zf-cursor-not-allowed': _channelOptions.length === 0,
    }" v-if="!isLoading" />
    </form>
</template>